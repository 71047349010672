import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }
  
  body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: inherit;
    line-height: 1.75;
    color: ${(props) => props.theme.colors.grey.dark};
  }

  a {
    color: ${(props) => props.theme.colors.orange};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p, ul, ol {
    margin: 1.25em 0;
  }

  ul, ol {
    padding-left: 1.5em;

    p {
      margin: 0;
    }

    ul, ol {
      margin: 0;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.25;
    margin: 1.25em auto 0 auto;
  }

  b, strong {
    font-weight: 700;
  }
`;

export default GlobalStyle;
