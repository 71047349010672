import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Feature, { FeatureContainer } from '@components/feature/index';

const Container = styled.section`
  margin: 1rem auto;

  ${FeatureContainer} {
    margin: 1rem;
  }

  ${({ theme, displayMode, displaySize }) => theme.media.tablet`
    margin: 2rem auto;

    ${displaySize === 'copyWidth' && `
        max-width: 55rem;
    `}

    ${displaySize === 'pageWidth' && `
      max-width: 80rem;
    `}
    
    ${FeatureContainer} {
      margin: 1.5rem;
    }

    ${displayMode !== 'Rows' && `
      padding: 0 .5rem;
      display: flex;
      flex-wrap: wrap;

      ${FeatureContainer} {
        margin: 1rem;
      }
    `}

    ${displayMode === 'Two columns' && `
      ${FeatureContainer} {
        width: calc(50% - 2rem);
      }
    `}

    ${displayMode === 'Three columns' && `
      ${FeatureContainer} {
        width: calc(33.3333% - 2rem);
      }
    `}
  `}
`;

const Features = ({
  displayMode, displaySize, displayStyle, features,
}) => (
  <Container
    displayMode={displayMode}
    displaySize={displaySize === 'Copy width' ? 'copyWidth' : 'pageWidth'}
  >
    {features && features.map((feature) => (
      <Feature
        key={feature.id}
        displayMode={displayMode === 'Rows' ? 'horizontal' : 'vertical'}
        displayStyle={displayStyle === 'Circular' ? 'circular' : 'square'}
        {...feature}
      />
    ))}
  </Container>
);

Features.propTypes = {
  displayMode: PropTypes.string.isRequired,
  displaySize: PropTypes.string.isRequired,
  displayStyle: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape(Feature.propTypes)),
};

Features.defaultProps = {
  features: [],
};

export const fragment = graphql`
  fragment BlockFeaturesFields on ContentfulBlockFeatures {
    id
    title
    displayMode
    displaySize
    displayStyle
    features {
      ...FeatureFields
    }
  }
`;

export default Features;
