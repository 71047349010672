import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import richTextRenderer from '../../rich-text';
import Image from '../../media/image';
import EmbeddedResource from '../../media/embedded-resource';

const Container = styled.section`
  position: relative;
`;

const Media = styled.div`
  background-color: ${(props) => props.theme.colors.grey.medium};
  
  ${({ theme }) => theme.media.tablet`
    min-height: 24rem;
  `}
`;

const RichText = styled.div`
  ${({ theme }) => theme.media.tablet`
    font-size: 1.25rem;
  `}

  a {
    color: inherit;
  }

  h1 {
    margin: 0;
    font-size: 1.75rem;

    ${({ theme }) => theme.media.tablet`
      font-size: 2.75rem;
    `}
  }

  h2 {
    margin: 0;
    font-size: 1.5rem;
    
    ${({ theme }) => theme.media.tablet`
      font-size: 2.25rem;
    `}
  }

  p:empty:last-child {
    display: none;
  }
`;

const TextPanel = styled.div`

  > div > div > ${RichText} {
    padding: 1rem;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.backgrounds.green};
  }

  ${({ theme }) => theme.media.tablet`
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    > div {
      display: flex;
      justify-content: space-between;

      &:before {
        content: "";
        flex: 1;
        background-color: ${(props) => props.theme.colors.transparent.greens.dark};
      }

      &:after {
        content: "";
        flex: 1;
      }

      > div {
        width: 100%;
        max-width: 80rem;

        > ${RichText} {
          max-width: 30rem;
          padding: 1.5rem;
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.transparent.greens.dark};
        }
      }
    }
  `}
`;

const Hero = ({
  locale, copy, media, autoPlayMedia,
}) => (
  <Container>
    {media && (
    <Media>
      {media.image && media.image.fluid && (<Image loading="eager" fadeIn={false} fluid={media.image.fluid} />)}
      {media.embedCode && (
      <EmbeddedResource
        id={media.id}
        title={media.title}
        embedCode={media.embedCode.embedCode}
        autoPlay={autoPlayMedia}
      />
      )}
    </Media>
    )}
    <TextPanel>
      <div>
        <div>
          {copy && (
          <RichText>
            {richTextRenderer(copy.json, locale)}
          </RichText>
          )}
        </div>
      </div>
    </TextPanel>
  </Container>
);

Hero.propTypes = {
  locale: PropTypes.string.isRequired,
  media: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
    embedCode: PropTypes.shape({
      embedCode: PropTypes.string,
    }),
  }).isRequired,
  copy: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  autoPlayMedia: PropTypes.bool,
};

Hero.defaultProps = {
  copy: {
    json: {},
  },
  autoPlayMedia: true,
};

export const fragment = graphql`
  fragment BlockHeroFields on ContentfulBlockHero {
    id
    locale: node_locale
    title
    media {
      ...ImageFields
      ...EmbeddedResourceFields
    }
    copy {
      json
    }
  }
`;

export default Hero;
