import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

export default () => (
  <CookieConsent
    style={{
      backgroundColor: '#414141',
      alignItems: 'center',
    }}
    contentStyle={{
      fontSize: '.875rem',
      lineHeight: '1.5',
      margin: '1rem 1rem 1rem 1rem',
    }}
    buttonText="Ok, ik accepteer de cookies"
    buttonStyle={{
      margin: '1rem 1rem',
      padding: '.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      color: 'white',
      backgroundColor: '#6c9715',
    }}
  >
    Om u zo goed mogelijk van dienst te kunnen zijn,
    maakt onze website gebruik van cookies. Kijk voor meer informatie in ons
    {' '}
    <Link to="/cookie-statement">Cookie Statement</Link>
    .
  </CookieConsent>
);
