import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import richTextRenderer from '../../rich-text';

const Container = styled.article`
  padding: 1rem;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: ${(props) => props.theme.colors.backgrounds.brown};
  text-align: center;

  ${({ theme }) => theme.media.tablet`
    margin: 0;
    font-size: 1.5rem;
    padding: 1.5rem;
  `}
`;

const RichText = styled.div`
  margin: 0 auto;
  max-width: 52rem;
`;

const Intro = ({ locale, copy }) => (
  <Container>
    <RichText>
      {copy && richTextRenderer(copy.json, locale)}
    </RichText>
  </Container>
);

Intro.propTypes = {
  locale: PropTypes.string.isRequired,
  copy: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }).isRequired,
};

export const fragment = graphql`
  fragment BlockIntroFields on ContentfulBlockIntro {
    id
    title
    locale: node_locale
    copy {
      json
    }
  }
`;

export default Intro;
