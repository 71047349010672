import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { style } from 'styled-system';

export const EmbedContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const YoutubeEmbedContainer = styled(EmbedContainer)`
  padding-bottom: 56.25%;

  iframe, span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const EmbeddedResource = ({
  id, title, embedCode, autoPlay = true, showControls = true,
}) => {
  const urlRegex = /(https?:\/\/[^"]+)/g;
  const urlMatches = embedCode.match(urlRegex);
  const url = urlRegex.test(embedCode) ? new URL(urlMatches[0]) : undefined;

  if (url && url.href.indexOf('https://www.youtube.com/embed/') !== -1) {
    const params = url.searchParams;

    params.set('autoplay', autoPlay ? 1 : 0);
    params.set('loop', 1);
    params.set('disablekb', 1);
    params.set('controls', showControls ? 1 : 0);
    params.set('fs', 0);
    params.set('modestbranding', 1);
    params.set('mute', 1);

    return (
      <YoutubeEmbedContainer id={id}>
        <iframe
          title={title}
          frameBorder="0"
          src={url.href}
          allow="autoplay; encrypted-media"
        />
        {!showControls && <span />}
      </YoutubeEmbedContainer>
    );
  }

  return (
    <EmbedContainer id={id} dangerouslySetInnerHTML={{ __html: embedCode }} />
  );
};

EmbeddedResource.defaultProps = {
  autoPlay: true,
  showControls: true,
};

EmbeddedResource.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  embedCode: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  showControls: PropTypes.bool,
};

export const fragment = graphql`
  fragment EmbeddedResourceFields on ContentfulEmbeddedResource {
    id
    title
    embedCode {
      embedCode
    }
  }
`;

export default EmbeddedResource;
