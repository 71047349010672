import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  typography, space, layout, color, flexbox,
} from 'styled-system';

import {
  useInput,
  TextInput,
  StyledTextInput,
} from '@components/form';

import Icon from '../icon';

const Container = styled.div`
  ${color}
  ${space}
`;

const StyledSearchForm = styled.form`
  display: flex;

  ${StyledTextInput} {
    margin: 0;

    > span.input input[type] {
      height: 2.5rem;
      border-color: ${(props) => props.theme.colors.brown};
    }
  }
`;

const Nav = styled.nav`
  ${flexbox}
  ${typography}
  ${space}
  ${layout}
  ${color}

  padding: 0 .75rem;

  ${({ theme }) => theme.media.tablet`
    padding: 0 1.5rem;
  `}

  a, button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;

    line-height: 1;
    text-align: center;
    color: inherit;
    border: 0;
    border-left: 1px solid ${(props) => props.theme.colors.grey.medium};
    background-color: transparent;

    &:hover {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.brown};
    }
    span {
      display: none;
    }
  }
`;

const SearchForm = () => {
  const [searchTermIsVisible, setSearchFormIsVisible] = useState(false);
  const searchTerm = useInput('');

  function toggleSearchFormVisibility() {
    setSearchFormIsVisible(!searchTermIsVisible);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (searchTerm.value === '') {
      return;
    }

    window.location.href = `https://www.google.nl/search?q=site%3Ahospitaalbroeders.nl+${searchTerm.value}`;
  }

  return (
    <StyledSearchForm onSubmit={handleSubmit}>
      {searchTermIsVisible && (
      <TextInput
        {...searchTerm.bind}
        name="term"
        required
      />
      )}
      <button aria-label="Zoeken" type="submit" onClick={toggleSearchFormVisibility}>
        <Icon icon="search" />
        <span> Zoeken</span>
      </button>
    </StyledSearchForm>
  );
};

const SocialBar = () => (
  <Container bg="grey.light">
    <Nav
      bg="grey.light"
      color="brown"
      mx="auto"
      maxWidth="80rem"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      {/* <SearchForm /> */}
      <a href="https://www.facebook.com/HospitaalBroeders/" aria-label="Volg ons op Facebook">
        <Icon icon={['fab', 'facebook-f']} />
      </a>
      <a href="https://www.instagram.com/hospitaalbroeders/" aria-label="Bekijk wat we doen op Instagram">
        <Icon icon={['fab', 'instagram']} />
      </a>
      <a href="https://www.youtube.com/user/HospitaalBroedersNL" aria-label="Bekijk wat we doen op YouTube">
        <Icon icon={['fab', 'youtube']} />
      </a>
      <a href="https://www.linkedin.com/company/hospitaalbroeders" aria-label="Volg ons op LinkedIn">
        <Icon icon={['fab', 'linkedin']} />
      </a>
    </Nav>
  </Container>
);

SocialBar.propTypes = {

};

export default SocialBar;
