import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import {
  Error,
  useInput,
  useRadioInput,
  TextInput,
  RadioInput,
  CheckboxInput,
  Button,
  StyledForm,
  StyledFieldset,
  StyledLegend,
  StyledRadioInput,
  StyledTextInput
} from '@components/form';

import imgPp from '../../../images/pp.png';

const StyledPaymentMethods = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledPaymentProvidersImage = styled.img`
  height: 32px;
  width: auto;
  margin-left: 0.5rem;
`;

export const StyledDonate = styled.div`
  ${StyledFieldset} {
  }

  ${StyledRadioInput} {
  }
`;

const FlexOptionsFieldset = styled(StyledFieldset)`
  ${({ theme }) => theme.media.tablet`
    > div {
      display: flex;
      margin-right: -1rem;
    }
  `}

  ${StyledRadioInput} {
    flex: 1;

    > span {
      display: block;
      width: 100%;
      white-space: nowrap;
      padding: 1rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.25;
      color: ${props => props.theme.colors.grey.dark};
      background-color: ${props => props.theme.colors.grey.light};

      ${({ theme }) => theme.media.tablet`
        margin-right: 1rem;
        margin-bottom: 0;
      `}
    }

    > input {
      visibility: hidden;
      position: absolute;
    }

    > input:checked + span {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.orange};
    }
  }
`;

const FrequencyFieldset = styled(FlexOptionsFieldset)``;

const AmountFieldset = styled(FlexOptionsFieldset)`
  ${StyledRadioInput} {
    > span {
      font-size: 1.75rem;
      /* text-align: center; */
    }

    > input:checked + ${StyledTextInput} {
      flex: 1;
      padding: 1rem;
      margin-bottom: 0.5rem;
      background-color: ${props => props.theme.colors.orange};

      ${({ theme }) => theme.media.tablet`
        margin-right: 1rem;
      `}

      .input {
        display: flex;
        font-size: 1.75rem;
        font-weight: 700;

        > input,
        > .prefix {
          border: 0;
          margin: 0;
          padding: 0;
          font-size: 1.75rem;
          font-weight: 700;
          color: ${props => props.theme.colors.white};
          background-color: ${props => props.theme.colors.orange};
        }
      }
    }
  }
`;

const StyledNameContainer = styled.div`
  ${({ theme }) => theme.media.phone`
    display: flex;
    justify-content: flex-start;
    /* margin: 1rem; */
    margin-right: -1rem;
    margin-bottom: 1rem;

    ${StyledTextInput} {
      flex: 1;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  `}
`;

const StyledSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PrivacyCopy = styled.p`
  /* margin: .25rem auto; */
  color: ${props => props.theme.colors.grey.dark};
  font-size: 0.75rem;
  line-height: 1.25;
  color: #ababab;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const FrequencyLabels = {
  'one-time': 'Eenmalig',
  monthly: 'Maandelijks',
  yearly: 'Jaarlijks'
};

const Donate = ({ headline, options, redirectPage }) => {
  const [error, setError] = useState();
  const { campaign = 'WB2101', frequencies } = options;

  const getOptionsForFrequency = f =>
    frequencies.find(({ frequency: value }) => f === value);

  const urlParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );

  const urlFrequency = urlParams.get('frequency');
  const defaultFrequency = getOptionsForFrequency(urlFrequency)
    ? urlFrequency
    : frequencies[0].frequency;

  const urlAmount = urlParams.get('amount');
  const isOtherAmount =
    urlAmount &&
    !getOptionsForFrequency(defaultFrequency).amounts.find(
      amount => amount === urlAmount
    );
  const defaultAmount =
    urlAmount || getOptionsForFrequency(defaultFrequency).amounts[0] || '10.00';

  const isFrequenciesFieldsetVisible = frequencies.length > 1;
  const frequencyFieldsetLegend = 'Ik geef';
  const amountsFieldsetLegend = isFrequenciesFieldsetVisible
    ? 'Een bedrag van'
    : 'Ik geef eenmalig een bedrag van';

  const frequency = useRadioInput(defaultFrequency);
  const amount = useRadioInput(isOtherAmount ? '0.00' : defaultAmount);
  const otherAmount = useInput((isOtherAmount && urlAmount) || '');
  const firstName = useInput('');
  const lastName = useInput('');
  const email = useInput('');
  const phone = useInput('');
  const address = useInput('');
  const zipcode = useInput('');
  const streetNumber = useInput('');
  const city = useInput('');
  const bankAccount = useInput('');
  const donateReason = useRadioInput('');
  const otherDonateReason = useInput('');
  const stayUpToDate = useRadioInput('');
  const transactionCosts = useRadioInput(false);

  const getFullname = () => [firstName.value, lastName.value].join(' ');

  const getTotalAmount = () => {
    let totalAmount = parseFloat(amount.checkedValue || 0);

    if (amount.checkedValue === '0.00') {
      const parsedOtherAmount = parseFloat(otherAmount.value.replace(',', '.'));

      totalAmount = !Number.isNaN(parsedOtherAmount) ? parsedOtherAmount : 0;
    }

    return (
      totalAmount + parseFloat(transactionCosts.checkedValue || 0)
    ).toFixed(2);
  };

  const formatCurrency = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
  });

  const redirectUrl = new URL(redirectPage.slug, process.env.GATSBY_HOST);

  const onSubmit = async event => {
    event.preventDefault();
    setError(undefined);

    redirectUrl.search = `?amount=${getTotalAmount()}&frequency=${
      frequency.checkedValue
    }`;

    const response = await fetch('/.netlify/functions/make-donation', {
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        donateFor: headline,
        campaign:
          getOptionsForFrequency(frequency.checkedValue).campaign || campaign,
        frequency: frequency.checkedValue,
        amount: getTotalAmount(),
        name: getFullname(),
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        address: address.value,
        zipcode: zipcode.value,
        streetNumber: streetNumber.value,
        city: city.value,
        bankAccount: bankAccount.value,
        donateReason: donateReason.checkedValue,
        otherDonateReason: otherDonateReason.value,
        stayUpToDate: stayUpToDate.checkedValue,
        template: getOptionsForFrequency(frequency.checkedValue)
          .emailTemplateId,
        redirectUrl: redirectUrl.toString()
      })
    });

    const data = await response.json();

    if (!response.ok) {
      console.log('donate response not ok: ', data);
      setError(data.error || undefined);
      return;
    }

    if (typeof window !== 'undefined') {
      window.location.replace(data.redirectUrl);
    }
  };

  const renderFrequencyRadioInput = value => (
    <RadioInput
      key={`frequency${value}`}
      name="frequency"
      {...frequency.bind}
      value={value}
      label={FrequencyLabels[value]}
    />
  );

  const renderAmountRadioInput = value => {
    const key = `amount${value}`;

    if (value === '0.00') {
      return (
        <RadioInput
          key={key}
          name="amount"
          {...amount.bind}
          value={value}
          label={
            amount.checkedValue === '0.00' ? `€ ${otherAmount.value}` : 'Anders'
          }
        >
          {amount.checkedValue === '0.00' && (
            <TextInput
              required
              autoFocus
              type="text"
              lang="nl-NL"
              pattern="[0-9]+([,\.][0-9]+)?"
              onChange={e => {
                otherAmount.setValue(e.currentTarget.value);
              }}
              value={otherAmount.value}
              name="otherAmount"
              prefix="€&nbsp;"
            />
          )}
        </RadioInput>
      );
    }

    return (
      <RadioInput
        key={key}
        name="amount"
        {...amount.bind}
        value={value}
        label={formatCurrency.format(value)}
      />
    );
  };

  return (
    <StyledDonate>
      <StyledForm onSubmit={onSubmit}>
        {isFrequenciesFieldsetVisible && (
          <FrequencyFieldset>
            <StyledLegend>{frequencyFieldsetLegend}</StyledLegend>
            <div>
              {frequencies.map(({ frequency: value }) =>
                renderFrequencyRadioInput(value)
              )}
            </div>
          </FrequencyFieldset>
        )}
        <AmountFieldset>
          <StyledLegend>{amountsFieldsetLegend}</StyledLegend>
          <div>
            {getOptionsForFrequency(frequency.checkedValue).amounts.map(value =>
              renderAmountRadioInput(value)
            )}
          </div>
        </AmountFieldset>
        <StyledFieldset>
          <StyledLegend>Over jou</StyledLegend>
          <StyledNameContainer>
            <TextInput
              {...firstName.bind}
              name="first-name"
              label="Voornaam"
              required
            />
            <TextInput {...lastName.bind} name="last-name" label="Achternaam" />
          </StyledNameContainer>

          {getOptionsForFrequency(frequency.checkedValue)
            .showZipcodeAndStreetNumber === true && (
            <StyledNameContainer>
              <TextInput {...zipcode.bind} name="postcode" label="Postcode" />
              <TextInput
                {...streetNumber.bind}
                name="street-number"
                label="Huisnummer"
              />
            </StyledNameContainer>
          )}

          <TextInput {...email.bind} name="email" label="Email" required />
          {frequency.checkedValue !== 'one-time' && (
            <div>
              <StyledNameContainer>
                <TextInput
                  {...zipcode.bind}
                  name="postcode"
                  label="Postcode"
                  required
                />
                <TextInput
                  {...streetNumber.bind}
                  name="street-number"
                  label="Huisnummer"
                  required
                />
              </StyledNameContainer>
              <TextInput {...phone.bind} name="telefoon" label="Telefoon" />
              <TextInput
                {...bankAccount.bind}
                name="rekeningnummer"
                label="Rekeningnummer"
                required
              />
            </div>
          )}
          {getOptionsForFrequency(frequency.checkedValue).showDonateReason && (
            <StyledFieldset>
              <legend>Waarom doneer je?</legend>
              <RadioInput
                {...donateReason.bind}
                name="waarom-doneer-je"
                value="Ik sprak een van jullie mensen op straat"
                label="Ik sprak een van jullie mensen op straat"
              />
              <RadioInput
                {...donateReason.bind}
                name="waarom-doneer-je"
                value="Ik ontving post van jullie"
                label="Ik ontving post van jullie"
              />
              <RadioInput
                {...donateReason.bind}
                name="waarom-doneer-je"
                value="Naar aanleiding van een digitale nieuwsbrief"
                label="Naar aanleiding van een digitale nieuwsbrief"
              />
              <RadioInput
                {...donateReason.bind}
                name="waarom-doneer-je"
                value="Ik zag jullie op Facebook"
                label="Ik zag jullie op Facebook"
              />
              <RadioInput
                {...donateReason.bind}
                name="waarom-doneer-je"
                value="other"
                label="Ik heb een andere reden"
              />
              {donateReason.checkedValue === 'other' && (
                <TextInput
                  {...otherDonateReason.bind}
                  name="why-donate-other"
                  label=""
                  required
                />
              )}
            </StyledFieldset>
          )}
          {getOptionsForFrequency(frequency.checkedValue).showStayUpToDate && (
            <StyledFieldset>
              <legend>
                We houden je graag op de hoogte van de bestedingen van je
                donatie en onze activiteiten. Geef hieronder aan hoe je het
                liefst op de hoogte wilt blijven:
              </legend>
              <RadioInput
                {...stayUpToDate.bind}
                name="op-de-hoogte-blijven-middels"
                value="email"
                label="Per email"
                required
              />
              <RadioInput
                {...stayUpToDate.bind}
                name="op-de-hoogte-blijven-middels"
                value="post"
                label="Per post"
                required
              />
              {stayUpToDate.checkedValue === 'post' && (
                <div>
                  <p>Waar mogen we de post naar toe sturen?</p>
                  <TextInput
                    {...address.bind}
                    name="adres"
                    label="Adres"
                    required
                  />
                  <TextInput
                    {...zipcode.bind}
                    name="postcode"
                    label="Postcode"
                    required
                  />
                  <TextInput
                    {...city.bind}
                    name="plaats"
                    label="Plaats"
                    required
                  />
                </div>
              )}
            </StyledFieldset>
          )}
          {getOptionsForFrequency(frequency.checkedValue)
            .showTransactionCosts &&
            frequency.checkedValue === 'one-time' && (
              <StyledFieldset>
                <legend>Wil je ook de transactiekosten betalen?</legend>
                <CheckboxInput
                  {...transactionCosts.bind}
                  name="transactiekosten"
                  value="0.30"
                  label="Geef dan € 0,30 extra."
                  type="checkbox"
                />
              </StyledFieldset>
            )}
        </StyledFieldset>
        <StyledSubmitContainer>
          <div>
            {error && (
              <Error>
                <span>Er is een fout opgetreden: </span>
                {error}
              </Error>
            )}
          </div>
          <Button type="submit">
            {`Doneer ${formatCurrency.format(getTotalAmount())}`}
          </Button>
        </StyledSubmitContainer>
        <PrivacyCopy>
          Gegevens worden alleen door HospitaalBroeders vastgelegd en nooit aan
          derden verstrekt.
          <br />
          Wil je weten hoe wij met jouw gegevens omgaan? Bekijk dan ons{' '}
          <a href="https://www.hospitaalbroeders.nl/privacy-beleid/">
            privacy beleid
          </a>
          .
        </PrivacyCopy>
        <StyledPaymentMethods>
          <StyledPaymentProvidersImage src={imgPp} />
        </StyledPaymentMethods>
      </StyledForm>
    </StyledDonate>
  );
};

Donate.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  options: PropTypes.shape({
    campaign: PropTypes.string,
    frequencies: PropTypes.arrayOf(
      PropTypes.shape({
        frequency: PropTypes.string.isRequired,
        amounts: PropTypes.arrayOf(PropTypes.string).isRequired,
        showStayUpToDate: PropTypes.bool.isRequired,
        showDonateReason: PropTypes.bool.isRequired,
        showTransactionCosts: PropTypes.bool.isRequired,
        emailTemplateId: PropTypes.number.isRequired
      })
    ).isRequired
  }).isRequired,
  redirectPage: PropTypes.shape({
    slug: PropTypes.string.isRequired
  }).isRequired
};

export const fragment = graphql`
  fragment BlockDonateFields on ContentfulBlockDonate {
    id
    title
    locale: node_locale
    headline
    options {
      campaign
      frequencies {
        campaign
        frequency
        amounts
        emailTemplateId
        showStayUpToDate
        showDonateReason
        showTransactionCosts
        showZipcodeAndStreetNumber
      }
    }
    redirectPage {
      ... on ContentfulLandingPage {
        slug
      }
      ... on ContentfulCampaignPage {
        slug
      }
    }
  }
`;

export default Donate;
