import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  typography, space, layout, color, flexbox,
} from 'styled-system';

import Icon from '../icon';

const Container = styled.div`
  ${color}
  ${space}
`;

const Nav = styled.nav`
  ${flexbox}
  ${typography}
  ${space}
  ${layout}
  ${color}
  padding: 1.5rem 1rem;
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.media.tablet`
    padding: 1.5rem;
  `}
  
  h2 {
    margin: 0;
    margin-bottom: 1rem;
    flex: 1 0 100%;
    font-size: 1.375rem;
    
    ${({ theme }) => theme.media.tablet`
      margin: 0;
      display: inline-block;
      margin-right: 2rem;
      flex: 0 1 auto;
      font-size: 1.5rem;
    `}
  }
  
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;

    text-align: center;
    color: inherit;
    line-height: 1;
    background-color: ${(props) => props.theme.colors.grey.light};
    border-radius: 50%;

    &:hover {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.orange};
    }
    span {
      display: none;
    }
  }
`;

const Divider = styled.span`
  display: inline-block;
  border: 1px solid white;
  height: 2rem;
  margin: 0 1rem 0 0rem;
`;

const SocialFooterBar = () => (
  <Container bg="grey.medium">
    <Nav
      bg="grey.medium"
      color="brown"
      mx="auto"
    >
      <h2>
        Volg ons
      </h2>
      <a href="https://www.facebook.com/HospitaalBroeders/" aria-label="Facebook">
        <Icon icon={['fab', 'facebook-f']} />
      </a>
      <a href="https://www.instagram.com/hospitaalbroeders/" aria-label="Instagram">
        <Icon icon={['fab', 'instagram']} />
      </a>
      <a href="https://www.youtube.com/user/HospitaalBroedersNL" aria-label="YouTube">
        <Icon icon={['fab', 'youtube']} />
      </a>
      <a href="https://www.linkedin.com/company/hospitaalbroeders" aria-label="LinkedIn">
        <Icon icon={['fab', 'linkedin']} />
      </a>
      <Divider />
      <a href="mailto:info@hospitaalbroeders.nl" aria-label="Email">
        <Icon icon={['fa', 'envelope']} />
      </a>
    </Nav>
  </Container>
);

SocialFooterBar.propTypes = {

};

export default SocialFooterBar;
