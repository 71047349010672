import React from 'react';
import PropTypes from 'prop-types';

import * as blockLibrary from './block-library';

const BlockComponentNotFound = ({ type }) => {
  const message = `A block for type "${type}" was not found.`;

  return <p>{message}</p>;
};

const Block = ({ block }) => {
  const BlockComponent = blockLibrary[block.type] || BlockComponentNotFound;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BlockComponent {...block} />
  );
};

Block.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default Block;
