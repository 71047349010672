import React from 'react';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';

import Table from '../table';
import EmbeddedResource, { EmbedContainer } from '../media/embedded-resource';

const localizedValue = (object, locale = 'en') => object[locale];

const localizedFields = (fields, locale = 'en') => {
  const res = {};

  Object.keys(fields).forEach((key) => {
    const value = localizedValue(fields[key], locale);

    switch (typeof value) {
      case 'object':
        if (Array.isArray(value)) {
          res[key] = value.map(({ fields }) => localizedFields(fields, locale));
        } else {
          res[key] = value.fields ? localizedFields(value.fields, locale) : value;
        }
        break;

      default:
        res[key] = value;
        break;
    }
  });

  return res;
};

const richTextRenderer = (json, locale = 'en') => {
  const options = {
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderNode: {
      // normal link
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.indexOf('https://www.youtube.com/embed/') !== -1) {
          return (
            <EmbedContainer>
              <iframe
                title="Youtube Embed"
                width="560"
                height="315"
                src={node.data.uri}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </EmbedContainer>
          );
        }

        return <a href={node.data.uri}>{children}</a>;
      },

      // link to a contentful entry
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { target } = node.data;

        if (target.fields) {
          const { title, slug } = target.fields;
          const localizedTitle = localizedValue(title, locale);
          const localizedSlug = `/${localizedValue(slug, locale)}`;

          return (
            <Link title={localizedTitle} to={localizedSlug}>
              {children}
            </Link>
          );
        }

        return <a href="#failing-entry-hyperlink">{children}</a>;
      },

      // link to asset
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const { target } = node.data;

        if (target.fields) {
          const { file: { url } } = localizedFields(target.fields, locale);

          if (url) {
            return (
              <a href={url}>
                {children}
              </a>
            );
          }
        }

        return <a href="#failing-asset-hyperlink">{children}</a>;
      },

      // Image
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { target } = node.data;

        function renderImage(url, title, alt, contentType, href) {
          const format = contentType === 'image/png' ? 'png' : 'jpg';

          return (
            <p className="embedded-image">
              {href ? (
                <a href={href}>
                  <img src={`${url}?w=960&q=80&fm=${format}`} title={title} alt={alt} />
                </a>
              ) : <img src={`${url}?w=960&q=80&fm=${format}`} title={title} alt={alt} />}
            </p>
          );
        }

        if (target.sys && target.fields) {
          const { id, contentType } = target.sys;
          const fields = localizedFields(target.fields, locale);

          switch (contentType.sys.id) {
            case 'image':
              return fields.image.file ? renderImage(
                fields.image.file.url,
                fields.seoTitle ?? undefined,
                fields.seoAlt || fields.seoTitle || undefined,
                fields.image.file.contentType,
                fields.link || undefined,
              ) : null;

            case 'table':
              return fields.data ? <Table data={fields.data.tableData} /> : null;

            case 'embeddedResource':
              return (
                <EmbeddedResource
                  id={id}
                  title={fields.title}
                  embedCode={fields.embedCode}
                  autoPlay={false}
                />
              );

            default:
              return (
                <p>
                  Note: embedded entry "
                  {contentType.sys.id}
                  " failed to load.
                </p>
              );
          }
        }
      },
    },
  };

  return documentToReactComponents(json, options);
};

export default richTextRenderer;
