import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { typography, space, layout, color, flexbox } from 'styled-system';

import Icon from '../icon';

const Container = styled.div`
  ${color}
  ${space}
  margin: 1rem;
  max-width: 52rem;
  border-top: 1px solid ${props => props.theme.colors.grey.medium};
  padding-top: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    margin: 2rem auto;
  `}
`;

const Headline = styled.h2`
  margin-top: 0;
  font-size: 1.375rem;

  ${({ theme }) => theme.media.tablet`
    font-size: 1.5rem;
  `}
`;

const Nav = styled.nav`
  ${flexbox}
  ${typography}
  ${space}
  ${layout}
  ${color}

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;

    text-align: center;
    color: inherit;
    line-height: 1;
    background-color: ${props => props.theme.colors.grey.light};
    border-radius: 50%;

    &:hover {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.orange};
    }
    span {
      display: none;
    }
  }
`;

const SocialShare = ({ headline }) => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  useEffect(() => {
    const metaDescriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const metaTitleElement = document.querySelector('title');

    const metaTitle = metaTitleElement
      ? metaTitleElement.text
      : site.siteMetadata.title;
    const metaDescription = metaDescriptionElement
      ? metaDescriptionElement.content
      : site.siteMetadata.description;

    setTitle(encodeURIComponent(metaTitle));
    setText(encodeURIComponent(metaDescription));
    setUrl(encodeURIComponent(window.location.href));
  }, []);

  return (
    <Container>
      <Headline>{headline}</Headline>
      <Nav
        py="1.25rem"
        color="green"
        mx="auto"
        maxWidth="80rem"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <a href={`mailto:?subject=${title}&body=${url}`}>
          <Icon icon="envelope" />
          <span>E-mail</span>
        </a>
        <a href={`https://facebook.com/sharer/sharer.php?u=${url}`}>
          <Icon icon={['fab', 'facebook-f']} />
          <span>Facebook</span>
        </a>
        <a href={`https://x.com/intent/tweet/?text=${text}&url=${url}`}>
          <Icon icon={['fab', 'x-twitter']} />
          <span>X</span>
        </a>
        <a href={`whatsapp://send?text=${text}%20${url}`}>
          <Icon icon={['fab', 'whatsapp']} />
          <span> WhatsApp</span>
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}&summary=${text}&source=${url}`}
        >
          <Icon icon={['fab', 'linkedin']} />
          <span> LinkedIn</span>
        </a>
      </Nav>
    </Container>
  );
};

SocialShare.defaultProps = {
  headline: 'Deel deze pagina'
};

SocialShare.propTypes = {
  headline: PropTypes.string
};

export const fragment = graphql`
  fragment BlockShareFields on ContentfulBlockShare {
    id
    title
    locale: node_locale
    headline
  }
`;

export default SocialShare;
