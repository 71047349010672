import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ fluid, loading = 'lazy', fadeIn = true }) => (
  <Img
    loading={loading}
    fluid={fluid}
    fadeIn={fadeIn}
  />
);

Image.propTypes = {
  loading: PropTypes.string.isRequired,
  fadeIn: PropTypes.bool.isRequired,
  fluid: PropTypes.shape({
    aspectRatio: PropTypes.number.isRequired,
  }).isRequired,
};

export const fragment = graphql`

  fragment ImageFields on ContentfulImage {
    id
    image {
      ...AssetFields
    }
  }

  fragment AssetFields on ContentfulAsset {
    fluid(maxWidth: 960, quality: 80) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }

    fluidSmall: fluid(maxWidth: 640, quality: 80) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
`;

export default Image;
