import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import richTextRenderer from '../../rich-text';

export const Container = styled.article`

`;

export const RichText = styled.div`
  img {
    max-width: 100%;
  }
`;

const Copy = ({ entryId, locale, copy }) => (
  <Container id={entryId}>
    <RichText>
      {copy && richTextRenderer(copy.json, locale)}
    </RichText>
  </Container>
);

Copy.defaultProps = {
  //
};

Copy.propTypes = {
  entryId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  copy: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }).isRequired,
};

export const fragment = graphql`
  fragment BlockCopyFields on ContentfulBlockCopy {
    id
    entryId: contentful_id
    title
    locale: node_locale
    copy {
      json
    }
  }
`;

export default Copy;
