import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Container as CopyContainer,
  RichText as CopyRichText,
} from '@components/block-library/copy';

import {
  StyledDonate,
} from '@components/block-library/donate';

import {
  StyledPetition,
} from '@components/block-library/petition';

import {
  FormContainer as JsonSchemaFormContainer,
} from '@components/block-library/json-schema-form';

const Container = styled.main`

  ${CopyContainer} {
    margin: 1rem;

    ${({ theme }) => theme.media.tablet`
      margin: 2rem auto;
      max-width: 52rem;
    `}
  }

  ${StyledDonate} {
    margin: 1rem;

    ${({ theme }) => theme.media.tablet`
      margin: 2rem auto;
      max-width: 52rem;
    `}
  }
  
  ${StyledPetition} {
    margin: 1rem;

    ${({ theme }) => theme.media.tablet`
      margin: 2rem auto;
      max-width: 52rem;
    `}
  }

  ${CopyRichText},
  ${JsonSchemaFormContainer} {
    h1, h2 {
      margin: 2rem auto 1rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey.medium};
      font-size: 1.75rem;
      text-align: left;

      ${({ theme }) => theme.media.tablet`
        padding: 1.75rem;
        margin: 2rem auto;
        font-size: 2.25rem;
        text-align: center;
      `};
    }
    
    h1 {
      font-size: 2rem;

      ${({ theme }) => theme.media.tablet`
        font-size: 2.75rem;
      `};
    }

    h3 {
      margin: 2rem auto 1rem;
      font-size: 1.375rem;
      color: ${(props) => props.theme.colors.green};

      ${({ theme }) => theme.media.tablet`
        font-size: 1.5rem;
      `}
    }
  }
`;

const Main = ({ children }) => <Container>{children}</Container>;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
