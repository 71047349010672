import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Image from '@components/media/image';
import richTextRenderer from '@components/rich-text';

const MediaContainer = styled.div`
  position: relative;
  /* padding-bottom: 56.25%; */
  padding-bottom: 66.666%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey.medium};
`;

const Media = styled.div`
  transition: transform .25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const TextContainer = styled.div`
  padding: 1rem;

  blockquote {
    margin: 1rem auto;
    font-size: 1.375em;
    font-style: italic;
  }  
`;

const Headline = styled.h2`
  margin: 0;
  font-size: 1.375em;
`;

const Label = styled.span`
  display: inline-block;
  /* font-size: .875rem; */
  font-weight: 600;
  padding: .125rem .5rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.orange};

  position: absolute;
  left: 0;
  bottom: 0;
`;

const RichText = styled.div`
  p:empty:last-child {
    display: none;
  }
`;

export const FeatureContainer = styled.article`
  position: relative;

  ${({ hasLink, theme }) => hasLink && `
    cursor: pointer;

    a {
      color: inherit;
      text-decoration: underline;
    }

    &:hover {
      ${Media} {
        transform: scale(1.05);
      }
    }
  `};

  ${({ theme }) => theme.media.tablet`
    ${({ displayMode }) => displayMode === 'horizontal' && `
      display: flex;
      font-size: 1.125em;

      ${Headline} {
        font-size: 1.5em;
      }

      ${TextContainer},
      ${MediaContainer} {
        flex: 1 0 50%;
      }

      ${TextContainer} {
        padding: 4rem;
      }

      ${MediaContainer} {
        // padding-bottom: 31.25%; // 16:5
        padding-bottom: 0;

        ${Media} {
          position: relative;
          height: 100%;

          .gatsby-image-wrapper {
            height: 100%;
          }
        }
      }
    `}
  `}
`;

export const SquareFeatureContainer = styled(FeatureContainer)`
  background-color: ${({ theme }) => theme.colors.backgrounds.greens.light};

  ${({ theme, displayMode }) => displayMode === 'horizontal' && theme.media.tablet`
    ${TextContainer} {
      padding: 2rem;
    }

    ${Label} {
      right: 0;
      left: auto;
    }
  `}
  

  ${({ hasLink, theme }) => hasLink && `
    
    a {
      text-decoration: none;
    }

    &:hover {
      background-color: ${theme.colors.transparent.orange};
    }
  `};

`;

export const CircularFeatureContainer = styled(FeatureContainer)`
  padding-top: 2rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.greens.light};

  ${MediaContainer} {
    margin: 0 auto;
    position: relative;
    width: 75%;
    padding-bottom: 75%;
    border-radius: 50%;

    ${Media} {
      position: absolute;
      height: 100%;

      .gatsby-image-wrapper {
        height: 100%;
      }
    }
  }

  ${TextContainer} {
    padding: 1rem;
  }

  ${Headline} {
    text-align: center;
  }

  ${({ theme }) => theme.media.tablet`
    ${TextContainer} {
      padding: 2rem 2rem 0 2rem;
    }

    ${({ displayMode }) => displayMode === 'horizontal' && `
      padding: 1rem 2rem;
      align-items: center;
      
      ${MediaContainer} {
        flex: 1 0 33.333%;
        padding-bottom: 33.333%;
      }
      
      ${TextContainer} {
        flex: 1 0 66.666%;
        padding: 2rem;
      }
     
      ${Headline} {
        text-align: left;
      }
    `}
  `}

`;

const Feature = ({
  locale, headline, copy, image, label, displayMode, displayStyle,
}) => {
  const ref = useRef(null);
  const [hasLink, setHasLink] = useState(false);

  const Container = displayStyle === 'circular' ? CircularFeatureContainer : SquareFeatureContainer;

  function getLink() {
    return ref.current ? ref.current.getElementsByTagName('a').item(0) : null;
  }

  function onFeatureClick() {
    const a = getLink();

    if (document && a && a.href) {
      document.location.href = a.href;
    }
  }

  useEffect(() => {
    setHasLink(getLink());
  }, [ref]);

  return (
    <Container
      ref={ref}
      displayMode={displayMode}
      hasLink={hasLink}
      onClick={onFeatureClick}
    >
      <MediaContainer>
        <Media>
          {image && image.image && <Image fluid={image.image.fluidSmall} />}
        </Media>
        {label && <Label>{label}</Label>}
      </MediaContainer>
      <TextContainer>
        <Headline>{headline}</Headline>
        <RichText>
          {richTextRenderer(copy.json, locale)}
        </RichText>
      </TextContainer>
    </Container>
  );
};

Feature.defaultProps = {
  image: undefined,
  displayMode: 'horizontal',
  displayStyle: 'circular',
  label: null,
};

Feature.propTypes = {
  locale: PropTypes.string.isRequired,
  displayMode: PropTypes.oneOf(['horizontal', 'vertical']),
  displayStyle: PropTypes.oneOf(['square', 'circular']),
  headline: PropTypes.string.isRequired,
  copy: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    image: PropTypes.object.isRequired,
  }),
  label: PropTypes.string,
};

export const fragment = graphql`
  fragment FeatureFields on ContentfulFeature {
    id
    title
    locale: node_locale
    headline
    copy {
      json
    }
    image {
      ...ImageFields
    }
    label
  }
`;

export default Feature;
