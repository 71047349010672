import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';

import Hero from '../hero';

const Container = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.colors.backgrounds.green};
  padding-bottom: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    padding-bottom: 0;
  `}

  .BrainhubCarouselItem {
    display: block;
  }

  .BrainhubCarousel__dots {
    position: absolute;
    bottom: .5rem;
    width: 100%;

    .BrainhubCarousel__dot:before {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

const ReactCarouselLoading = <div />;
const ReactCarousel = React.lazy(() => import('@brainhubeu/react-carousel'));

const Carousel = ({ elements }) => {
  const isSSR = typeof window === 'undefined';

  function renderElements(limit = 0) {
    return elements.slice(0, limit).map((element) => (
      <Hero
        key={element.id}
        autoPlayMedia={false}
        {...element}
      />
    ));
  }

  if (isSSR) {
    return <Container>{renderElements(1)}</Container>;
  }

  return (
    <React.Suspense fallback={ReactCarouselLoading}>
      <Container>
        <ReactCarousel
          dots
          slidesPerPage={1}
          autoPlay={8000}
          stopAutoPlayOnHover
          infinite
        >
          {renderElements(elements.length)}
        </ReactCarousel>
      </Container>
    </React.Suspense>
  );
};

Carousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape(Hero.propTypes)),
};

Carousel.defaultProps = {
  elements: [],
};

export const fragment = graphql`
  fragment BlockCarouselFields on ContentfulBlockCarousel {
    id
    locale: node_locale
    title
    elements {
      ...BlockHeroFields
    }
  }
`;

export default Carousel;
