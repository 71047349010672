import React from 'react';
import styled from 'styled-components';
import { typography, space, layout, color } from 'styled-system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
  faXTwitter,
  faWhatsapp,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { faSearch, faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
  faXTwitter,
  faWhatsapp,
  faLinkedin,
  faSearch,
  faEnvelope
);

const StyledIcon = styled(FontAwesomeIcon)`
  ${typography}
  ${space}
  ${layout}
  ${color}
`;

const Icon = ({ icon, ...props }) => <StyledIcon icon={icon} {...props} />;

export default Icon;
