import React from 'react';
import PropTypes from 'prop-types';

import Block from './block';

const Blocks = ({ blocks }) => {
  const components = blocks.map(
    (block) => <Block key={`block${block.id}`} block={block} />,
  );

  return (
    <>
      {components}
    </>
  );
};

Blocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
};

export default Blocks;
