import React, { lazy, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import loadable from '@loadable/component';

import {
  Error,
  Button,
} from '@components/form';
import richTextRenderer from '@components/rich-text';

// const Form = lazy(() => import('@rjsf/core'));
const Form = loadable(() => import('@rjsf/core'));

// develop and test forms
// import schema from './schemas/wijzigingsformulier.json';
// import schema from './schemas/nalatenschap-formulier.json';
// import schema from './schemas/postvoorkeur-formulier.json';

// defines settings for common form fields
const uiEmail = {
  'ui:options': {
    inputType: 'email',
  },
};

const uiPhone = {
  'ui:options': {
    inputType: 'tel',
  },
};

const uiSchema = {
  email: uiEmail,
  phoneNumber: uiPhone,
  person: {
    email: uiEmail,
    phoneNumber: uiPhone,
  },
};

export const FormContainer = styled.div`
  margin: 2rem auto 4rem auto;
  padding: 0 1rem;
  max-width: 54em;

  form {
    margin: 0;

    > div > fieldset {
      border: 0;
      margin: 0;
      padding: 0;

      > legend {
        text-align: center;
      }

      fieldset {
        padding: 1rem;
        margin: 1rem auto;
        border: 2px solid ${(props) => props.theme.colors.grey.light};

        legend {
          margin: .5rem 0;
          text-align: left;
          font-weight: 700;
          width: 100%;
          margin: .5rem auto;
          padding: .5rem 1rem;
          font-size: 1.25rem;
          line-height: 1.25;
          color: ${(props) => props.theme.colors.grey.light};
          background-color: ${(props) => props.theme.colors.green};
        }

        .form-group {
          margin-bottom: 1rem;

          > label {
            display: block;
            margin-bottom: .25rem;
            line-height: 1.25;

            .required {
              display: inline-block;
              margin-left: .5rem;
            }
          }

          > input[type] {
            display: block;
            width: 100%;
            padding: .5rem;
            border: 1px solid;
            border-color: ${(props) => props.theme.colors.grey.medium};
            background-color: ${(props) => props.theme.colors.white};
          }

          > select {
            width: 100%;
            padding: .5rem;
            border: 1px solid;
            border-color: ${(props) => props.theme.colors.grey.medium};
            background-color: ${(props) => props.theme.colors.white};
          }
        }
      }
    }
  }
`;

const StyledSubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Intro = styled.div`
  >:first-child {
    margin-top: 0;
  }

  >:last-child {
    margin-bottom: 0;
  }
`;

const Outro = styled.div`
  
`;

const PrivacyCopy = styled.p`
  color: ${(props) => props.theme.colors.grey.dark};
  font-size: .75rem;
  line-height: 1.25;
  color: #ababab;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const JsonSchemaForm = ({
  entryId,
  title,
  locale,
  headline,
  intro,
  outro,
  submitButtonLabel,
  redirectPage,
  formSchemaJson,
  options,
}) => {
  if (!formSchemaJson || !options) {
    return null;
  }

  const [error, setError] = useState();
  const [formData, setFormData] = useState({});
  const schema = JSON.parse(formSchemaJson.formSchemaJson);
  const { emailTemplateId } = options;

  const onSubmit = async ({ formData }, event) => {
    event.preventDefault();
    setError(undefined);

    const { person } = formData;

    const response = await fetch('/.netlify/functions/submit-form', {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...formData,
        ...person,
        email: person.email,
        subject: title,
        template: emailTemplateId,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      console.log('response not ok: ', data);
      setError(data.error || undefined);
      return true;
    }

    if (typeof window !== 'undefined') {
      console.log('redirecting to', redirectPage.slug);
      window.location.replace(`/${redirectPage.slug}`);
    }

    return false;
  };

  return (
    <FormContainer id={entryId}>
      {headline && <h2>{headline}</h2>}
      {intro && (
      <Intro>
        {richTextRenderer(intro.json, locale)}
      </Intro>
      )}
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={({ formData }) => setFormData(formData)}
        onSubmit={onSubmit}
        showErrorList={false}
      >
        {outro && (
          <Outro>
            {richTextRenderer(outro.json, locale)}
          </Outro>
        )}
        <StyledSubmitContainer>
          <div>
            {error && (
            <Error>
              <span>Er is een fout opgetreden: </span>
              {error}
            </Error>
            )}
          </div>
          <Button type="submit">
            {submitButtonLabel}
          </Button>
        </StyledSubmitContainer>
        <PrivacyCopy>
          Wil je weten hoe wij met jouw gegevens omgaan? Check dan ons
          {' '}
          <a href="https://www.hospitaalbroeders.nl/privacy-beleid/">privacy beleid</a>
          .
        </PrivacyCopy>
      </Form>
    </FormContainer>
  );
};

JsonSchemaForm.defaultProps = {
  intro: {
    json: {},
  },
  outro: {
    json: {},
  },
};

JsonSchemaForm.propTypes = {
  entryId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  intro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  outro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  submitButtonLabel: PropTypes.string.isRequired,
  redirectPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    emailTemplateId: PropTypes.number.isRequired,
  }).isRequired,
};

export const fragment = graphql`
  fragment BlockFormFields on ContentfulBlockForm {
    id
    entryId: contentful_id
    title
    locale: node_locale
    headline
    intro {
      json
    }
    outro {
      json
    }
    submitButtonLabel
    redirectPage {
      ... on ContentfulLandingPage {
        slug
      }
      ... on ContentfulCampaignPage {
        slug
      }
    }
    formSchemaJson {
      formSchemaJson
    }
    options {
      emailTemplateId
    }
  }
`;

export default JsonSchemaForm;
