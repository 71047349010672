import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTable = styled.div`
  overflow: scroll;

  ${({ theme }) => theme.media.tablet`
    overflow: visible;
  `}

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    line-height: 1.375;
  }

  tr > td {
    padding: .5rem;
    padding-right: 1rem;
    vertical-align: top;
    border-right: 1px solid ${({ theme }) => theme.colors.grey.medium};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.medium};

    &:last-child {
      border-right: none;
    }
  }

  tr:last-child > td {
    border-bottom: none;
  }
`;

const Table = ({ data }) => {
  function renderColumnsForRow(row, rowIndex) {
    return row.map((value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <td key={`row-${rowIndex}--cell-${index}`}>
        {value}
      </td>
    ));
  }

  function renderRows() {
    return data.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={`row-${index}`}>
        {renderColumnsForRow(row, index)}
      </tr>
    ));
  }

  return (
    <StyledTable>
      <table>
        <tbody>
          {renderRows()}
        </tbody>
      </table>
    </StyledTable>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default Table;
