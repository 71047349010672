import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import {
  Error,
  useInput,
  useRadioInput,
  StyledForm,
  StyledFieldset,
  StyledLegend,
  RadioInput,
  CheckboxInput,
  TextInput,
  Button,
  StyledTextInput,
} from '@components/form';

export const MailingListForm = styled(StyledForm)`

  ${({ theme }) => theme.media.tablet`
    margin: 2rem auto;
    max-width: 52rem;
  `}

  ${StyledFieldset} {
    margin: 1rem auto 0;
  }

  ${StyledLegend} {
    margin-bottom: 1rem;
  }
`;

const StyledNameContainer = styled.div`
  ${({ theme }) => theme.media.phone`
    display: flex;
    justify-content: flex-start;
    /* margin: 1rem; */
    margin-right: -1rem;
    margin-bottom: .5rem;

    ${StyledTextInput} {
      flex: 1;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  `}
`;

const StyledSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${Error} {
    line-height: 1.25;
    font-size: .75rem;
  }

  ${Button} {
    font-size: 1rem;
    padding: 1rem;
  }
`;

const PrivacyCopy = styled.p`
  /* margin: .25rem auto; */
  color: ${(props) => props.theme.colors.grey.dark};
  font-size: .75rem;
  line-height: 1.25;
  color: #ababab;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const MailingList = ({ id, locale }) => {
  const [error, setError] = useState();
  const [hasSubscribed, setHasSubscribed] = useState(false);

  const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');

  const name = useInput('');
  const email = useInput(urlParams.get('email'));

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(undefined);

    // eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      // eslint-disable-next-line no-undef
      grecaptcha.execute('6Lc6nzQbAAAAACXYBhBnVcyGuptrYeVYm1fSWfb4', { action: 'submit' }).then(async (token) => {
        const options = {
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            firstName: name.value,
            email: email.value,
            token,
          }),
        };

        const response = await fetch('/.netlify/functions/subscribe-mailing-list', options);

        if (!response.ok) {
          console.log('response not ok: ', response);

          let data;

          try {
            data = await response.json();
          } catch (error) {
            console.error(error);
            setError('Onbekende error. Probeer het later nog eens.');
            return;
          }

          if (data.error) {
            setError(data.error);
          } else {
            setError('Probeer het later nog eens.');
          }

          return;
        }

        setHasSubscribed(true);
      });
    });
  };

  const onSubmitFooterForm = (event) => {
    event.preventDefault();
    setError(undefined);

    if (typeof window !== 'undefined') {
      window.location.assign(`/digitale-nieuwsbrief?email=${email.value}#7srYR4CeUVu7tspdyu9BMf`);
    }
  };

  // Footer form
  if (id === '46efab53-4ddd-502c-b216-cd0cea9e396d') {
    return (
      <MailingListForm id={id} dataLocale={locale} onSubmit={onSubmitFooterForm}>
        <>
          <StyledFieldset>
            <StyledLegend>
              Schrijf je in voor onze nieuwsbrief
            </StyledLegend>
            <TextInput
              {...email.bind}
              name="email"
              label="Email"
              required
            />
          </StyledFieldset>
          <StyledSubmitContainer>
            <div>
              {error && (
              <Error>
                <span>Er is een fout opgetreden: </span>
                {error}
              </Error>
              )}
            </div>
            <Button type="submit">Inschrijven</Button>
          </StyledSubmitContainer>
        </>
      </MailingListForm>
    );
  }

  return (
    <MailingListForm id={id} dataLocale={locale} onSubmit={onSubmit}>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6Lc6nzQbAAAAACXYBhBnVcyGuptrYeVYm1fSWfb4" />
      </Helmet>
      {!hasSubscribed ? (
        <>
          <StyledFieldset>
            <StyledLegend>
              Je gegevens
            </StyledLegend>
            <StyledNameContainer>
              <TextInput
                {...name.bind}
                name="name"
                label="Voornaam"
                required
              />
            </StyledNameContainer>
            <TextInput
              {...email.bind}
              name="email"
              label="Email"
              required
            />
          </StyledFieldset>
          <StyledSubmitContainer>
            <div>
              {error && (
              <Error>
                <span>Er is een fout opgetreden: </span>
                {error}
              </Error>
              )}
            </div>
            <Button type="submit">Inschrijven</Button>
          </StyledSubmitContainer>
          <PrivacyCopy>
            Wil je weten hoe wij met jouw gegevens omgaan? Check dan ons
            {' '}
            <a href="https://www.hospitaalbroeders.nl/privacy-beleid/">privacy beleid</a>
            .
          </PrivacyCopy>
        </>
      ) : (
        <StyledFieldset>
          <StyledLegend>
            Bedankt voor het inschrijven
          </StyledLegend>
          <p>Je ontvangt vanaf nu informatie uit onze ziekenhuizen en projecten.</p>
        </StyledFieldset>
      )}
    </MailingListForm>
  );
};

MailingList.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export const fragment = graphql`
  fragment BlockMailingListFields on ContentfulBlockMailingList {
    id
    locale: node_locale
  }
`;

export default MailingList;
