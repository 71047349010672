/* eslint-disable prefer-destructuring */
import { css } from 'styled-components';

/**
 * This is our custom theme where we define global styles.
 * It should serve as a guideline for styling, but not all styles *have* to be taken from here.
 */
const breakpoints = ['24rem', '54rem', '60rem', '80rem'];

// default is a small phone
breakpoints.phone = breakpoints[0]; // large phone
breakpoints.tablet = breakpoints[1];
breakpoints.laptop = breakpoints[2];
breakpoints.desktop = breakpoints[3];

const media = Object.keys(breakpoints).reduce((acc, label) => {
  // console.log(acc, label);
  acc[label] = (...args) => css`
     @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
     }
  `;
  return acc;
}, {});

/**
 * Primary: Colors to use for actionable items, such as links, buttons etc.
 * Grey: Colors for items that are not that important
 */
const colors = {
  white: 'white',
  black: 'black',
  green: '#6c9715',
  greens: {
    medium: '#6c9715',
    dark: '#928711',
  },
  blue: '#000080',
  orange: '#f39100',
  brown: '#683629',
  grey: {
    dark: '#414141',
    medium: '#e2e2e2',
    light: '#f4f4f4',
  },
  transparent: {
    white: 'rgba(255, 255, 255, 0.9)',
    orange: 'rgba(243, 145, 0, 0.2)',
    greens: {
      light: 'rgba(108, 151, 21, .20)',
      medium: 'rgba(108, 151, 21, .45)',
      dark: 'rgba(146, 135, 17, .4)',
    },
  },
  backgrounds: {
    brown: '#8a7d71',
    green: '#beb77b',
    greens: {
      light: '#f6f5ec',
    },
  },
};

/**
 * Space is used for margin and padding scales.
 * It's recommended to use powers of two to ensure alignment across the entire project
 */
const space = [
  '0',
  '4px',
  '8px',
  '16px',
  '32px',
  '64px',
  '128px',
  '256px',
  '512px',
];

/**
 * Typographic scale
 */
const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '96px',
  '128px',
];

const lineHeights = [1, 1.125, 1.25, 1.5];

const fontWeights = {
  normal: 400,
  semibold: 600,
};

/**
 * Letter-spacing should vary, depending on usage of text
 */
const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
  labels: '0.05em',
};

/**
 * Border-radius
 */
const radii = ['0', '2px', '4px', '8px', '16px'];

const theme = {
  name: 'Default',
  breakpoints,
  media,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
};

export default theme;
