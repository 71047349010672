import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import {
  Error,
  useInput,
  useRadioInput,
  StyledForm,
  StyledFieldset,
  StyledLegend,
  RadioInput,
  CheckboxInput,
  TextInput,
  TextareaInput,
  Button,
  StyledTextInput,
} from '@components/form';
import richTextRenderer from '@components/rich-text';


export const StyledPetition = styled.div`
  margin: 2rem auto;

  ${StyledTextInput} {
    display: block;
    margin-bottom: 1rem;
  }
`;

const Intro = styled.div`
  >:first-child {
    margin-top: 0;
  }
`;

const Outro = styled.div`
  
`;

const StyledNameContainer = styled.div`
  ${({ theme }) => theme.media.phone`
    display: flex;
    justify-content: flex-start;
    margin-right: -1rem;
    margin-bottom: 1rem;

    ${StyledTextInput} {
      flex: 1;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  `}
`;

const StyledSubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PrivacyCopy = styled.p`
  /* margin: .25rem auto; */
  color: ${(props) => props.theme.colors.grey.dark};
  font-size: .75rem;
  line-height: 1.25;
  color: #ababab;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const Petition = ({
  entryId, title, locale, headline, intro, outro, submitButtonLabel, redirectPage, options,
}) => {
  const [error, setError] = useState();

  const firstName = useInput('');
  // const middleName = useInput('');
  const lastName = useInput('');
  const email = useInput('');
  const phone = useInput('');
  const remarks = useInput('');

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(undefined);

    const response = await fetch('/.netlify/functions/sign-petition', {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        title,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        remarks: remarks.value,
        // subject: ,
        template: options.emailTemplateId,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      console.log('response not ok: ', data);
      setError(data.error || undefined);
      return;
    }

    if (typeof window !== 'undefined') {
      const location = `/${redirectPage.slug}`;
      console.log('redirecting to', location);
      window.location.replace(location);
    }
  };

  return (
    <StyledPetition id={entryId}>
      <StyledForm onSubmit={onSubmit}>
        <StyledFieldset>
          <StyledLegend>{headline}</StyledLegend>
          {intro && (
          <Intro>
            {richTextRenderer(intro.json, locale)}
          </Intro>
          )}
          <StyledNameContainer>
            <TextInput
              {...firstName.bind}
              name="first-name"
              label="Voornaam"
              required
            />
            {/* <TextInput
              {...middleName.bind}
              name="middle-name"
              label="Tussenvoegsel(s)"
            /> */}
            <TextInput
              {...lastName.bind}
              name="last-name"
              label="Achternaam"
              required
            />
          </StyledNameContainer>
          <StyledNameContainer>
            <TextInput
              {...email.bind}
              name="email"
              label="Email"
              required
            />
            <TextInput
              {...phone.bind}
              name="telefoon"
              label="Telefoon"
            />
          </StyledNameContainer>
          {options.remarksVisible && (
            <TextareaInput
              {...remarks.bind}
              name="remarks"
              rows="5"
              maxlength={options.remarksMaxCharacters}
              label={options.remarksLabel}
            />
          )}
          {outro && (
          <Outro>
            {richTextRenderer(outro.json, locale)}
          </Outro>
          )}
          <StyledSubmitContainer>
            <div>
              {error && (
              <Error>
                <span>Er is een fout opgetreden: </span>
                {error}
              </Error>
              )}
            </div>
            <Button type="submit">
              {submitButtonLabel}
            </Button>
          </StyledSubmitContainer>
          <PrivacyCopy>
            Wil je weten hoe wij met jouw gegevens omgaan? Check dan ons
            {' '}
            <a href="https://www.hospitaalbroeders.nl/privacy-beleid/">privacy beleid</a>
            .
          </PrivacyCopy>
        </StyledFieldset>
      </StyledForm>
    </StyledPetition>
  );
};

Petition.defaultProps = {
  intro: {
    json: {},
  },
  outro: {
    json: {},
  },
  // options: {
  //   remarksVisible: false,
  //   remarksLabel: "",
  //   remarksMaxCharacters: 0,
  // }
};

Petition.propTypes = {
  entryId: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  intro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  outro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  submitButtonLabel: PropTypes.string.isRequired,
  redirectPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    emailTemplateId: PropTypes.number.isRequired,
    remarksVisible: PropTypes.bool,
    remarksLabel: PropTypes.string,
    remarksMaxCharacters: PropTypes.number,
  }).isRequired,
};

export const fragment = graphql`
  fragment BlockPetitionFields on ContentfulBlockPetition {
    id
    entryId: contentful_id
    title
    locale: node_locale
    headline
    intro {
      json
    }
    outro {
      json
    }
    submitButtonLabel
    redirectPage {
      ... on ContentfulLandingPage {
        slug
      }
      ... on ContentfulCampaignPage {
        slug
      }
    }
    options {
      id
      emailTemplateId
      remarksVisible
      remarksLabel
      remarksMaxCharacters
    }
  }
`;

export default Petition;
