import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const StyledForm = styled.form`

`;

export const StyledFieldset = styled.fieldset`
  padding: 1rem;
  margin: 2rem auto;
  border: 2px solid ${(props) => props.theme.colors.grey.light};

  > legend {
    margin: .5rem 0;
    text-align: left;
    font-weight: 700;
  }
`;

export const StyledLegend = styled.legend`
  width: 100%;
  margin: .5rem auto;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.grey.light};
  background-color: ${(props) => props.theme.colors.green};
`;

export const StyledRadioInput = styled.label`
  display: flex;
  align-items: baseline;
  width: 100%;
  line-height: 1.25;

  > input {
    flex: none;

    &:invalid {
      box-shadow: none;
    }
  }

  > span {
    flex: 1;
    padding: .25rem;
  }
`;

export const StyledCheckboxInput = styled.label`
  display: block;

  > span {
    padding: .5rem;
  }
`;

export const StyledTextInput = styled.label`
  display: block;
  margin-bottom: 1rem;

  > span.label {
    display: block;
    margin-bottom: .25rem;
    line-height: 1.25;
  }

  > span.input input[type],
  > span.input textarea {
    width: 100%;
    padding: .5rem;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.grey.medium};
    background-color: ${(props) => props.theme.colors.white};

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.colors.grey.dark};
      background-color: ${(props) => props.theme.colors.grey.light};
    }
  }
  
  > span.input textarea {
    resize: none;
  }
`;

export const Button = styled.button`
  display: block;
  padding: 1rem 2rem;
  border: none;
  font-size: 1.25rem;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.orange};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.green};
  }
`;

export const Error = styled.p`
  color: red;
`;

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

const useRadioInput = (initialCheckedValue) => {
  const [checkedValue, setCheckedValue] = useState(initialCheckedValue);

  return {
    checkedValue,
    setCheckedValue,
    reset: () => setCheckedValue(''),
    bind: {
      checkedValue,
      onChange: (event) => {
        setCheckedValue(event.currentTarget.checked ? event.currentTarget.value : '');
      },
    },
  };
};

const RadioInput = ({
  children, name, value, label, type = 'radio', required = false, onChange = () => {}, checkedValue = '',
}) => {
  const id = name + value;
  return (
    <>
      <StyledRadioInput htmlFor={id}>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          checked={value === checkedValue}
          onChange={onChange}
          required={required}
        />
        {children || (
        <span>
          {label}
        </span>
        )}
      </StyledRadioInput>
    </>
  );
};

const CheckboxInput = ({
  name, value, label, onChange = () => {}, checkedValue = false,
}) => {
  const id = name + value;
  const [isChecked, setIsChecked] = useState(checkedValue);
  const onChangeToggle = (event) => {
    setIsChecked(!isChecked);
    onChange(event);
  };

  return (
    <StyledCheckboxInput htmlFor={id}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChangeToggle}
      />
      <span>
        {' '}
        {label}
      </span>
    </StyledCheckboxInput>
  );
};

const TextInput = ({
  name, value, label, prefix = '', type = 'text', onChange = () => {}, required = false, autoFocus = false, ...restProps
}) => {
  const id = name;

  return (
    <StyledTextInput htmlFor={id}>
      {label && (
      <span className="label">
        {label}
      </span>
      )}
      <span className="input">
        {prefix && <span className="prefix">{prefix}</span>}
        <input type={type} id={id} name={name} value={value} onChange={onChange} required={required} autoFocus={autoFocus} {...restProps} />
      </span>
    </StyledTextInput>
  );
};

const TextareaInput = ({
  name, value, label, prefix = '', type = 'text', onChange = () => {}, required = false, autoFocus = false, ...restProps
}) => {
  const id = name;

  return (
    <StyledTextInput htmlFor={id}>
      {label && (
      <span className="label">
        {label}
      </span>
      )}
      <span className="input">
        <textarea type={type} id={id} name={name} value={value} onChange={onChange} required={required} autoFocus={autoFocus} {...restProps} />
      </span>
    </StyledTextInput>
  );
};


export {
  useInput,
  useRadioInput,
  RadioInput,
  CheckboxInput,
  TextInput,
  TextareaInput,
};
