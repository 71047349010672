import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

const MapboxMap = lazy(() => import('../../mapbox'));

const MapContainer = styled.div`
  margin: 0 auto;

  ${({ theme, displayMode, displaySize }) => theme.media.tablet`
    ${displaySize === 'copyWidth' && `
        max-width: 52rem;
        max-width: 77rem;
    `}

    ${displaySize === 'pageWidth' && `
      // max-width: 80rem;
      width: 100%;
    `}
  `}
`;

const MapAspectContainer = styled.div`
  position: relative;
  /* padding-bottom: 56.25%; */
  padding-bottom: 75%;
`;

function transformToGeoJson(data) {
  const features = data.map((item) => {
    const feature = {
      type: 'Feature',
      properties: item,
      geometry: {
        type: 'Point',
        coordinates: [
          item.location.lon,
          item.location.lat,
        ],
      },
    };

    return feature;
  });

  const res = {
    type: 'FeatureCollection',
    features,
  };

  return res;
}

const Map = ({
  id, locale, title, displaySize, data,
}) => {
  const layers = [
    {
      id: 'markers',
      source: 'markers',
      type: 'symbol',
      layout: {
        'icon-image': 'hospital-15',
        // 'icon-color': '#6c9715',
        'icon-size': 1.75,
        'icon-allow-overlap': true,
        'text-size': 13,
        'text-field': ['get', 'headline'],
        'text-offset': [1.25, 0],
        'text-anchor': 'left',
        'text-allow-overlap': true,
      },
    },
  ];

  const sources = {
    markers: {
      type: 'geojson',
      data: transformToGeoJson(data),
      cluster: false,
    },
  };

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  });

  const renderLoader = () => <p>De kaart is aan het laden, even geduld...</p>;

  return (
    <MapContainer
      displaySize={displaySize === 'Copy width' ? 'copyWidth' : 'pageWidth'}
    >
      <MapAspectContainer ref={ref}>
        {inView ? (
          <Suspense fallback={renderLoader()}>
            <MapboxMap
              locale={locale}
              sources={sources}
              layers={layers}
              width="100%"
              height="100%"
            />
          </Suspense>
        ) : null}
      </MapAspectContainer>
    </MapContainer>
  );
};

Map.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  displaySize: PropTypes.string.isRequired,
  // children: PropTypes.node.isRequired,
};

export const fragment = graphql`
  fragment BlockMapFields on ContentfulBlockMap {
    id
    locale: node_locale
    title
    displaySize
    data {
      headline
      description {
        json
      }
      location {
        lat
        lon
      }
    }
  }
`;

export default Map;
