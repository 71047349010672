import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import {
  Error,
  useInput,
  useRadioInput,
  TextInput,
  RadioInput,
  CheckboxInput,
  Button,
  StyledForm,
  StyledFieldset,
  StyledLegend,
  StyledRadioInput,
  StyledCheckboxInput,
  StyledTextInput,
} from '@components/form';

import richTextRenderer from '../../rich-text';

export const StyledDonate = styled.div`
  margin: 1rem;
  padding: 0 2rem 2rem 2rem;
  background-color: ${(props) => props.theme.colors.grey.light};

  ${({ theme }) => theme.media.tablet`
    margin: 4rem;
  `}

  ${({ theme }) => theme.media.desktop`
    margin: 4rem;
  `}
`;

const StyledDonateContainer = styled.div`
  margin: 0 auto;
  max-width: 78rem;
`;

const StyledDonateForm = styled(StyledForm)`
  ${({ theme }) => theme.media.tablet`
    display: flex;
    // align-items: center;
    justify-content: flex-end;
  `}
`;

export const RichText = styled.div`
  padding: .5rem;
`;


const FlexOptionsFieldset = styled(StyledFieldset)`
  border: none;
  margin: 0;
  padding: 0;
  
  ${StyledRadioInput} {
    flex: 1;

    > span {
      display: block;
      width: 100%;
      white-space: nowrap;
      padding: 1rem;
      margin-bottom: .5rem;
      font-weight: 700;
      /* font-size: 1.25rem; */
      line-height: 1.5;
      /* border: 1px solid; */
      /* border-color: ${(props) => props.theme.colors.grey.dark}; */
      background-color: ${(props) => props.theme.colors.grey.medium};

      margin: 0;
    }

    > input {
      visibility: hidden;
      position: absolute;
    }

    > input:checked + span {
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.orange};
      background-color: ${(props) => props.theme.colors.orange};
    }
  }
`;

const FrequenciesFieldset = styled(FlexOptionsFieldset)`
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

 ${StyledRadioInput} {
  margin: .5rem;

  > span {
    font-size: .875rem;
    padding: .5rem;
  }
 }
`;

const AmountFieldset = styled(FlexOptionsFieldset)`
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  ${StyledRadioInput} {
    flex: 1 1 0;
    margin: .5rem;

    > span {
      font-size: 1.75rem;
      /* text-align: center; */
    }

    > input:checked + ${StyledTextInput} {
      flex: 1 auto;
      margin: 0;
      padding: 1rem;
      background-color: ${(props) => props.theme.colors.orange};

      ${({ theme }) => theme.media.tablet`
        // margin-right: 1rem;
      `}

      .input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.75rem;
        font-weight: 700;

        > input,
        > .prefix {
          width: auto;
          flex: 1 auto;
          border: 0;
          margin: 0;
          padding: 0;
          font-size: 1.75rem;
          line-height: 1.5;
          font-weight: 700;
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.orange};
        }

        > .prefix {
          flex: 0;
        }

        > input {
          width: 75%;
        }
      }
    }
  }
`;

const StyledSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: 0;
    margin-left: 1.5rem;
  `}

  ${Button} {
    flex: 1;
    margin: .5rem;
    line-height: 1.5;
    font-size: 1.75rem;
    font-weight: 700;
  }
`;

const PrivacyCopy = styled.p`
  margin: .5rem;
  color: ${(props) => props.theme.colors.grey.dark};
  font-size: .75rem;
  line-height: 1.25;
  color: #ababab;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const FrequencyLabels = {
  'one-time': 'Eenmalig',
  monthly: 'Maandelijks',
  yearly: 'Jaarlijks',
};

const DonateCta = ({
  id, locale, title, copy, options, donatePage,
}) => {
  const [error, setError] = useState();

  const { frequencies } = options;
  const defaultFrequency = frequencies[0].frequency;
  const isFrequenciesFieldsetVisible = frequencies.length > 1;

  const frequency = useRadioInput(defaultFrequency);
  const amount = useRadioInput('25.00');
  const otherAmount = useInput('');

  const getOptionsForFrequency = (f) => frequencies.find(({ frequency: value }) => f === value);

  const formatCurrency = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  const getTotalAmount = () => {
    let totalAmount = parseFloat(amount.checkedValue || 0);

    if (amount.checkedValue === '0.00') {
      const parsedOtherAmount = parseFloat(otherAmount.value.replace(',', '.'));

      totalAmount = !Number.isNaN(parsedOtherAmount) ? parsedOtherAmount : 0;
    }

    return totalAmount.toFixed(2);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(undefined);

    if (typeof window !== 'undefined') {
      window.location.assign(`/${donatePage.slug}?frequency=${frequency.checkedValue}&amount=${getTotalAmount()}`);
    }
  };

  const renderFrequencyRadioInput = (value) => (
    <RadioInput key={`frequency${value}`} name="frequency" {...frequency.bind} value={value} label={FrequencyLabels[value]} />
  );

  const renderAmountRadioInput = (value) => {
    const key = `amount${value}`;

    if (value === '0.00') {
      return (
        <RadioInput key={key} name="amount" {...amount.bind} value={value} label={amount.checkedValue === '0.00' ? `€ ${otherAmount.value}` : 'Anders'}>
          {amount.checkedValue === '0.00' && (
          <TextInput
            required
            autoFocus
            type="text"
            lang="nl-NL"
            pattern="[0-9]+([,\.][0-9]+)?"
            onChange={(e) => {
              otherAmount.setValue(e.currentTarget.value);
            }}
            value={otherAmount.value}
            name="otherAmount"
            prefix="€&nbsp;"
          />
          )}
        </RadioInput>
      );
    }

    return <RadioInput key={key} name="amount" {...amount.bind} value={value} label={formatCurrency.format(value)} />;
  };

  return (
    <StyledDonate id={id}>
      <StyledDonateContainer>
        <RichText>
          {copy && richTextRenderer(copy.json, locale)}
        </RichText>
        <StyledDonateForm onSubmit={onSubmit}>
          <FrequenciesFieldset>
            {isFrequenciesFieldsetVisible && (
            <div>
              {frequencies.map(({ frequency: value }) => renderFrequencyRadioInput(value))}
            </div>
            )}
          </FrequenciesFieldset>
          <AmountFieldset>
            <div>
              {getOptionsForFrequency(frequency.checkedValue).amounts.map((value) => renderAmountRadioInput(value))}
            </div>
          </AmountFieldset>
          <StyledSubmitContainer>
            <div>
              {error && (
              <Error>
                <span>Er is een fout opgetreden: </span>
                {error}
              </Error>
              )}
            </div>
            <Button type="submit">
              Doneer
            </Button>
          </StyledSubmitContainer>
        </StyledDonateForm>
      </StyledDonateContainer>
    </StyledDonate>
  );
};

DonateCta.defaultProps = {
  copy: {
    json: undefined,
  },
};

DonateCta.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  options: PropTypes.shape({
    frequencies: PropTypes.arrayOf(PropTypes.shape({
      frequency: PropTypes.string.isRequired,
      amounts: PropTypes.arrayOf(PropTypes.string).isRequired,
    })).isRequired,
  }).isRequired,
  donatePage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export const fragment = graphql`
  fragment BlockDonateCtaFields on ContentfulBlockDonateCta {
    id
    locale: node_locale
    title
    copy {
      json
    }
    options {
      frequencies {
        frequency
        amounts
      }
    }
    donatePage {
      ... on ContentfulLandingPage {
        slug
      }
      ... on ContentfulCampaignPage {
        slug
      }
    }
  }
`;

export default DonateCta;
